import {useAuthStore} from "~/store/auth";

interface FetchOptions {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
    headers?: Record<string, string>
    transform?: (result: any) => any
    body?: string
    params?: Record<any, any>
}


export const useApiFetch = <DataT>(endpoint: string , options: FetchOptions = {}) => {
    const { apiUrl } = useRuntimeConfig().public
    const authStore  = useAuthStore()
    const { accessToken } = authStore
    options.headers = {
        ...options.headers,
        "X-Token": accessToken || ""
    }
    options.method = options.method || "GET"
    return useFetch(
        endpoint,
        {
            ...options,
            baseURL: apiUrl,
        }
    )
}
